import React from "react";
import "./Footer.css";
import marnImage from "../../images/marn.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="">
        <div className="row m-3 pt-5">
          <div className="col-lg-5 col-md-6 col-12 ft-1">
            <h3>About Real Migration and Education</h3>
            <p>
              Real Migration and Education is committed to making your visa and
              education journey smooth. Our aim is to simplify the process for
              our clients, helping them navigate the complex migration system
              with ease. We are always striving to improve ourselves.
            </p>
            <div className="footer-icon">
              <Link to="https://www.facebook.com/RealMigration">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to="https://www.facebook.com/RealMigration">
                <i className="fa-brands fa-facebook"></i>
              </Link>
              <Link to="https://www.youtube.com/@RealMigration">
                <i className="fa-brands fa-youtube"></i>
              </Link>
              <Link to="https://www.instagram.com/realmigrationau/">
                <i className="fa-brands fa-instagram"></i>
              </Link>
              <Link to="https://www.tiktok.com/@realmigration">
                <i className="fa-brands fa-tiktok"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 ft-2">
            <h3>Quick Links</h3>
            <ul className="navbar-nav-f">
              <li className="nav-item-f">
                <a href="/">Home</a>
              </li>
              <li className="nav-item-f">
                <a href="/">Visas</a>
              </li>
              <li className="nav-item-f">
                <a href="/about">About Us</a>
              </li>
              <li className="nav-item-f">
                <a href="/team">Our Team</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-6 col-12 ft-3">
            <h3>Contact Info</h3>
            <p>
              <i className="fa-solid fa-envelope"></i> info@realmigration.com.au
            </p>
            <p>
              <i className="fa-solid fa-location-dot"></i> 130 Kingston Road,
              Underwood. 4119, Australia
            </p>
            <img src={marnImage} alt="MARN Image" />
          </div>
        </div>
      </div>
      <div className="ft-4">
        <p>© 2024 Real Migration and Education. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
