import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Register from "./pages/Auth/Register/Register";
import Login from "./pages/Auth/Login/Login";
// import Home from "./pages/Home/Home";

import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import BookAnAppointment from "./pages/BookAnAppointment/BookAnAppointmnet";
import SkilledIndependent_189 from "./pages/Visas/SkilledVisas/SubClass_189/SkilledIndependent_189";
import OurTeam from "./pages/OurTeam/OurTeam";
import Services from "./pages/Services/Services";
import SkilledNominated_190 from "./pages/Visas/SkilledVisas/SubClass_190/SkilledNominated_190";
import SkilledWork_491 from "./pages/Visas/SkilledVisas/SubClass_491/SkilledWork_491";
import PermanentResident_191 from "./pages/Visas/SkilledVisas/SubClass_191/PermanentResident_191";
import SkilledResdence_887 from "./pages/Visas/SkilledVisas/SubClass_887/SkilledResdence_887";
import TemporaryActivityVisa_408 from "./pages/Visas/TemporaryActivityVisas/SubClass_408/TemporaryActivityVisa_408";
import TemporarySkill_400 from "./pages/Visas/TemporaryActivityVisas/SubClass_400/TemporarySkill_400";
import TssVisa_482 from "./pages/Visas/EmployerSponcerVisas/SubClass_482/TssVisa_482";
import SesVisa_494 from "./pages/Visas/EmployerSponcerVisas/SubClass_494/SesVisa_494";
import EnVisa_186 from "./pages/Visas/EmployerSponcerVisas/SubClass_186/EnVisa_186";
import StgVisa_485 from "./pages/Visas/GraduateVisas/SubClass_485/StgVisa_485";
import SrgVisa_476 from "./pages/Visas/GraduateVisas/SubClass_476/SrgVisa_476";
import VisitorVisa_600 from "./pages/Visas/VisitorVisas/SubClass_600/VisitorVisa_600";
import EtaVisa_601 from "./pages/Visas/VisitorVisas/SubClass_601/EtaVisa_601";
import EvVisa_651 from "./pages/Visas/VisitorVisas/SubClass_651/EvVisa_651";
import WHVisa_462 from "./pages/Visas/TouristVisas/SubClass_462/WHVisa_462";
import WHVisa_417 from "./pages/Visas/TouristVisas/SubClass_417/WHVisa_417";
import StudentVisa_500 from "./pages/Visas/Student&TrainingVisas/SubClass_500/StudentVisa_500";
import TrainingVisa_407 from "./pages/Visas/Student&TrainingVisas/SubClass_407/TrainingVisa_407";
import OnShurePVisa_820 from "./pages/Visas/PartnerVisas/SubClass_820_801/OnShurePVisa_820";
import OffShorePVisa_309 from "./pages/Visas/PartnerVisas/SubClass_309_100/OffShorePVisa_309";
import PMVisa_300 from "./pages/Visas/PartnerVisas/SubClass_300/PMVisa_300";
import ParentVisa_103 from "./pages/Visas/ParentVisis/SubClass_103/ParentVisa_103";
import AgedPVisa_804 from "./pages/Visas/ParentVisis/SubClass_804/AgedPVisa_804";
import CPVisa_143 from "./pages/Visas/ParentVisis/SubClass_143/CPVisa_143";
import CPVisa_173 from "./pages/Visas/ParentVisis/SubClass_173/CPVisa_173";
import CAPVisa_864 from "./pages/Visas/ParentVisis/SubClass_864/CAPVisa_864";
import CAPVisa_884 from "./pages/Visas/ParentVisis/SubClass_884/CAPVisa_884";
import AAT from "./pages/Visas/A_R_C/AAT/AAT";
import Noicc from "./pages/Visas/A_R_C/NOICC/Noicc";
import Visa_cancel_issue from "./pages/Visas/A_R_C/Visa_Cancel/Visa_cancel_issue";
import Blog from "./pages/Blog/Blog";
import AdminDashB from "./pages/Admin/AdminDashB";
import Dashboard from "./user/Dashboard";
import PrivateRoute from "./components/Routes/PrivateRoute";
import AdminRoute from "./components/Routes/AdminRoute";
import ShowUser from "./pages/Admin/Users/ShowUser/ShowUser";
import ShowAdmin from "./pages/Admin/Users/ShowAdmin/ShowAdmin";
import WriteBlog from "./pages/Admin/Post/WriteBlog/WriteBlog";
import ViewBlogs from "./pages/Admin/Post/ViewBlog/ViewBlogs";
import ViewBlog from "./pages/Admin/Post/ViewBlog/ViewBlog";
import AboutUsForm from "./pages/Admin/Forms/AboutUs/AboutUsForm";
import ScrollToTop from "./components/ScrollToTop";
import InitialAssessment from "./pages/InitialAssesment/InitialAssessment";
import FullRepresentation from "./pages/FullRepresentation/FullRepresentation";
import UserViewBlog from "./pages/Blog/ViewBlog/UserViewBlog";
import ViewAppointment from "./pages/Admin/Appointments/ViewAppointments/ViewAppointment";
import AddAgent from "./pages/Admin/Appointments/AddAgent/AddAgent";
import AddService from "./pages/Admin/Appointments/AddService/AddService";
import { AgentProvider } from "./pages/Admin/Appointments/AgentContext";
import SearchTool from "./pages/SerachTool/SearchTool";
import JObsCareer from "./pages/JobCareer/JObsCareer";
import DetailPage from "./pages/JobCareer/DetailPage/DetailPage";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import VisaForm from "./pages/VisaForm/VisaForm";

const Home = lazy(() => import("./pages/Home/Home"));

function App() {
  return (
    <AgentProvider>
      <ScrollToTop />
      <Suspense fallback={<h1>loading ..........</h1>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/initial-assessment-consultation"
            element={<InitialAssessment />}
          />
          <Route
            path="/full-representation-consultation"
            element={<FullRepresentation />}
          />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/team" element={<OurTeam />} />
          <Route path="/services" element={<Services />} />
          <Route path="/visa-related-form" element={<VisaForm />} />

          {/* <Route path="/search" element={<SearchTool />} /> */}
          <Route path="/search" element={<ComingSoon />} />
          <Route path="/jobs-and-career" element={<JObsCareer />} />
          <Route path="/detail-job" element={<DetailPage />} />
          {/* nested route implimentation for checking private routes */}
          <Route path="/dashboard/" element={<PrivateRoute />}>
            <Route path="user" element={<Dashboard />} />
          </Route>

          <Route path="/dashboard/" element={<AdminRoute />}>
            <Route path="admin" element={<AdminDashB />} />
            <Route path="admin/admins" element={<ShowAdmin />} />
            <Route path="admin/users" element={<ShowUser />} />
            <Route path="admin/writeblog" element={<WriteBlog />} />
            <Route path="admin/Viewblogs" element={<ViewBlogs />} />
            <Route path="admin/Viewblog/:id" element={<ViewBlog />} />
            <Route path="admin/contact-us" element={<AboutUsForm />} />
            <Route path="admin/addagent" element={<AddAgent />} />
            <Route path="admin/addservice" element={<AddService />} />
            {/* //appointment */}
            <Route path="admin/viewappointment" element={<ViewAppointment />} />
          </Route>

          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/appointment" element={<BookAnAppointment />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="user/Viewblog/:id" element={<UserViewBlog />} />
          {/* <Route path="/admin" element={<AdminDashB />} /> */}

          {/* // Visas Routes */}

          {/* Skilled visas */}
          <Route
            path="/skilledindependent-visa-189"
            element={<SkilledIndependent_189 />}
          />
          <Route
            path="/skilled-nominated-visa-190"
            element={<SkilledNominated_190 />}
          />
          <Route path="/skilled-work-visa-491" element={<SkilledWork_491 />} />
          <Route
            path="/permanent-residence-visa-191"
            element={<PermanentResident_191 />}
          />
          <Route
            path="/skilled-residence-visa-887"
            element={<SkilledResdence_887 />}
          />

          {/* Temporary Activity visas */}
          <Route
            path="/temporary-activity-408-visa"
            element={<TemporaryActivityVisa_408 />}
          />
          <Route path="/short-stay-visa-400" element={<TemporarySkill_400 />} />

          {/* Employer Sponsored Visas */}
          <Route
            path="/temporary-skills-shortage-visa-482"
            element={<TssVisa_482 />}
          />
          <Route
            path="/skilled-employer-sponsored-regional-494-visa"
            element={<SesVisa_494 />}
          />
          <Route
            path="/employer-nomination-186-visa"
            element={<EnVisa_186 />}
          />

          {/* Graduate Visas */}
          <Route path="/skilled-graduate-485-visa" element={<StgVisa_485 />} />
          <Route
            path="/skilled-recognised-graduate-476-visa"
            element={<SrgVisa_476 />}
          />

          {/* Visitor Visas  */}
          <Route path="/visitor-visa-600" element={<VisitorVisa_600 />} />
          <Route path="/evisitor-visa-651" element={<EvVisa_651 />} />
          <Route
            path="/electronic-travel-authority-visa-601"
            element={<EtaVisa_601 />}
          />

          {/* tourist Visas */}
          <Route path="/work-and-holiday-visa-462" element={<WHVisa_462 />} />
          <Route path="/working-holiday-visa-417" element={<WHVisa_417 />} />

          {/* Student And Training Visas */}
          <Route
            path="/australia-student-visa-500"
            element={<StudentVisa_500 />}
          />
          <Route
            path="/training-visa-subclass-407"
            element={<TrainingVisa_407 />}
          />

          {/* Partner Visas */}
          <Route
            path="/onshore-partner-visa-subclass-820-801"
            element={<OnShurePVisa_820 />}
          />
          <Route
            path="/offshore-partner-visa-309-100"
            element={<OffShorePVisa_309 />}
          />
          <Route
            path="/prospective-marriage-visa-300"
            element={<PMVisa_300 />}
          />

          {/* Parent visas */}
          <Route path="/parent-visa-103" element={<ParentVisa_103 />} />
          <Route path="/aged-parent-visa-804" element={<AgedPVisa_804 />} />
          <Route
            path="/contributory-parent-visa-143"
            element={<CPVisa_143 />}
          />
          <Route
            path="/contributory-parent-visa-173"
            element={<CPVisa_173 />}
          />
          <Route
            path="/contributory-aged-parent-visa-864"
            element={<CAPVisa_864 />}
          />
          <Route
            path="/contributory-aged-parent-visa-884"
            element={<CAPVisa_884 />}
          />
          {/* Parent visas */}
          <Route
            path="/administrative-appeals-tribunal-aat"
            element={<AAT />}
          />
          <Route
            path="/notice-of-intention-to-consider-cancellation-noicc"
            element={<Noicc />}
          />
          <Route path="/cancel-a-visa" element={<Visa_cancel_issue />} />

          {/* Comming soon  */}
          {/* <Route path="/comming soom" element={<PageNotFound />} /> */}

          {/* page not found  */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </AgentProvider>
  );
}

export default App;
