// src/components/VisaForm/VisaForm.js
import React, { useState, useRef } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  Table,
} from "react-bootstrap";
import Layout from "../../components/Layout/Layout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./VisaForm.css";

const VisaForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    travelHistory: [],
    residentialHistory: [], // Initialize as an empty array
    spouseDetails: {
      firstName: "",
      lastName: "",
      dob: null,
      travelHistory: [], // Initialize as an empty array
      residentialHistory: [], // Initialize as an empty array
    },
  });

  const [validated, setValidated] = useState(false);
  const [travelEntry, setTravelEntry] = useState({
    country: "",
    fromDate: null,
    toDate: null,
    reason: "",
  });

  const formRef = useRef();

  const countries = [
    "United States",
    "Australia",
    "Canada",
    "India",
    "United Kingdom",
    "Germany",
    "France",
    "Japan",
    "China",
    "Italy",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleTravelEntryChange = (field, value) => {
    setTravelEntry((prev) => ({ ...prev, [field]: value }));
  };

  const [residentialEntry, setResidentialEntry] = useState({
    country: "",
    address: "",
    state: "",
    postalCode: "",
    city: "",
  });

  const addTravelEntry = () => {
    if (
      !travelEntry.country ||
      !travelEntry.fromDate ||
      !travelEntry.toDate ||
      !travelEntry.reason
    ) {
      alert("Please fill all fields in Travel History.");
      return;
    }

    if (travelEntry.fromDate > travelEntry.toDate) {
      alert("From Date must be earlier than To Date.");
      return;
    }

    setFormData((prev) => ({
      ...prev,
      travelHistory: [...prev.travelHistory, travelEntry],
    }));

    // Reset travel entry
    setTravelEntry({
      country: "",
      fromDate: null,
      toDate: null,
      reason: "",
    });
  };

  const editTravelEntry = (index) => {
    const entryToEdit = formData.travelHistory[index];
    setTravelEntry(entryToEdit);

    setFormData((prev) => ({
      ...prev,
      travelHistory: prev.travelHistory.filter((_, i) => i !== index),
    }));
  };

  const deleteTravelEntry = (index) => {
    setFormData((prev) => ({
      ...prev,
      travelHistory: prev.travelHistory.filter((_, i) => i !== index),
    }));
  };

  const handleResidentialEntryChange = (field, value) => {
    setResidentialEntry((prev) => ({ ...prev, [field]: value }));
  };

  const addResidentialEntry = () => {
    if (
      !residentialEntry.country ||
      !residentialEntry.address ||
      !residentialEntry.state ||
      !residentialEntry.postalCode ||
      !residentialEntry.city
    ) {
      alert("Please fill all fields in Residential History.");
      return;
    }

    setFormData((prev) => ({
      ...prev,
      residentialHistory: [...prev.residentialHistory, residentialEntry],
    }));

    setResidentialEntry({
      country: "",
      address: "",
      state: "",
      postalCode: "",
      city: "",
    });
  };

  const editResidentialEntry = (index) => {
    const entryToEdit = formData.residentialHistory[index];
    setResidentialEntry(entryToEdit);

    setFormData((prev) => ({
      ...prev,
      residentialHistory: prev.residentialHistory.filter((_, i) => i !== index),
    }));
  };

  const deleteResidentialEntry = (index) => {
    setFormData((prev) => ({
      ...prev,
      residentialHistory: prev.residentialHistory.filter((_, i) => i !== index),
    }));
  };

  const handleRadioChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      isMarried: value === "yes", // Update marital status
      spouseDetails:
        value === "no" // Reset spouse details if "No" is selected
          ? {
              firstName: "",
              lastName: "",
              dob: null,
              travelHistory: [],
              residentialHistory: [],
            }
          : prev.spouseDetails,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = formRef.current;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      form.classList.add("was-validated");
    } else {
      console.log("Form submitted:", formData);
    }

    setValidated(true);
  };

  return (
    <Layout>
      <Container className="mt-5">
        <Card className="custom-shadow">
          <Card.Body>
            <h1 className="text-center mb-4">Visa Application Form</h1>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <Row>
                {/* Full Name */}
                <Col md={6}>
                  <Form.Group controlId="formFullName" className="mb-3">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="fullName"
                      placeholder="Enter your full name"
                      value={formData.fullName}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Full Name is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                {/* Email */}
                <Col md={6}>
                  <Form.Group controlId="formEmail" className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      A valid email is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <h4 className="mb-3">Enter Your Current Address</h4>

              {/* Street Address */}
              <Form.Group controlId="formStreetAddress" className="mb-3">
                <Form.Label>Street Address</Form.Label>
                <Form.Control
                  type="text"
                  name="streetAddress"
                  placeholder="Enter your street address"
                  value={formData.streetAddress}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Street Address is required.
                </Form.Control.Feedback>
              </Form.Group>

              {/* City and State */}
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formCity" className="mb-3">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      placeholder="Enter your city"
                      value={formData.city}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      City is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formState" className="mb-3">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      name="state"
                      placeholder="Enter your state"
                      value={formData.state}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      State is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              {/* Country and Postal Code */}
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formCountry" className="mb-3">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      name="country"
                      placeholder="Enter your country"
                      value={formData.country}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Country is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formPostalCode" className="mb-3">
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="postalCode"
                      placeholder="Enter your postal code"
                      value={formData.postalCode}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Postal Code is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              {/* Last 10 Year Travelling History */}
              <h4 className="mb-3">
                Please provide us your last 10-year travelling history
              </h4>

              {/* Country Selector */}
              <Form.Group controlId="travelHistoryCountry" className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  as="select"
                  value={travelEntry.country}
                  onChange={(e) =>
                    handleTravelEntryChange("country", e.target.value)
                  }
                  required
                >
                  <option value="" disabled>
                    Select a country
                  </option>
                  {countries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Country is required.
                </Form.Control.Feedback>
              </Form.Group>

              {/* From Date and To Date */}
              <Row>
                <Col md={6}>
                  <Form.Group
                    controlId="travelHistoryFromDate"
                    className="mb-3"
                  >
                    <Form.Label>From Date</Form.Label>
                    <DatePicker
                      selected={travelEntry.fromDate}
                      onChange={(date) =>
                        handleTravelEntryChange("fromDate", date)
                      }
                      className="form-control"
                      placeholderText="Select From Date"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="travelHistoryToDate" className="mb-3">
                    <Form.Label>To Date</Form.Label>
                    <DatePicker
                      selected={travelEntry.toDate}
                      onChange={(date) =>
                        handleTravelEntryChange("toDate", date)
                      }
                      className="form-control"
                      placeholderText="Select To Date"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Reason */}
              <Form.Group controlId="travelHistoryReason" className="mb-3">
                <Form.Label>Reason</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Explain your reason for travel"
                  value={travelEntry.reason}
                  onChange={(e) =>
                    handleTravelEntryChange("reason", e.target.value)
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Reason is required.
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                variant="secondary"
                className="mb-3"
                onClick={addTravelEntry}
              >
                Add Travel Entry
              </Button>

              {/* Display Travel History */}
              {formData.travelHistory.length > 0 && (
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Country</th>
                      <th>From Date</th>
                      <th>To Date</th>
                      <th>Reason</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.travelHistory.map((entry, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{entry.country}</td>
                        <td>{entry.fromDate.toLocaleDateString()}</td>
                        <td>{entry.toDate.toLocaleDateString()}</td>
                        <td>{entry.reason}</td>
                        <td>
                          <Button
                            variant="warning"
                            size="sm"
                            onClick={() => editTravelEntry(index)}
                            className="me-2"
                          >
                            Edit
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => deleteTravelEntry(index)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}

              {/* Residential History Section */}
              <h4 className="mb-3">
                Please provide us your residential history since traveling 16
                years
              </h4>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="residentialCountry" className="mb-3">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      as="select"
                      value={residentialEntry.country}
                      onChange={(e) =>
                        handleResidentialEntryChange("country", e.target.value)
                      }
                      required
                    >
                      <option value="" disabled>
                        Select a country
                      </option>
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Country is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="residentialAddress" className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter address"
                      value={residentialEntry.address}
                      onChange={(e) =>
                        handleResidentialEntryChange("address", e.target.value)
                      }
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Address is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="residentialState" className="mb-3">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter state"
                      value={residentialEntry.state}
                      onChange={(e) =>
                        handleResidentialEntryChange("state", e.target.value)
                      }
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      State is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    controlId="residentialPostalCode"
                    className="mb-3"
                  >
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter postal code"
                      value={residentialEntry.postalCode}
                      onChange={(e) =>
                        handleResidentialEntryChange(
                          "postalCode",
                          e.target.value
                        )
                      }
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Postal Code is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="residentialCity" className="mb-3">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter city"
                      value={residentialEntry.city}
                      onChange={(e) =>
                        handleResidentialEntryChange("city", e.target.value)
                      }
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      City is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Button
                variant="secondary"
                className="mb-3"
                onClick={addResidentialEntry}
              >
                Add Residential Entry
              </Button>

              {formData.residentialHistory.length > 0 && (
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Country</th>
                      <th>Address</th>
                      <th>State</th>
                      <th>Postal Code</th>
                      <th>City</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.residentialHistory.map((entry, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{entry.country}</td>
                        <td>{entry.address}</td>
                        <td>{entry.state}</td>
                        <td>{entry.postalCode}</td>
                        <td>{entry.city}</td>
                        <td>
                          <Button
                            variant="warning"
                            size="sm"
                            onClick={() => editResidentialEntry(index)}
                            className="me-2"
                          >
                            Edit
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => deleteResidentialEntry(index)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}

              <Form.Group controlId="maritalStatus" className="mb-3">
                <Form.Label>
                  Did you get married after the grant of your previous student
                  visa?
                </Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="isMarried"
                    value="yes"
                    checked={formData.isMarried}
                    onChange={() => handleRadioChange("yes")}
                    inline
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="isMarried"
                    value="no"
                    checked={!formData.isMarried}
                    onChange={() => handleRadioChange("no")}
                    inline
                  />
                </div>
              </Form.Group>

              {formData.isMarried && (
                <>
                  <h4 className="mb-3">Spouse Details</h4>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="spouseFirstName" className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter spouse's first name"
                          value={formData.spouseDetails.firstName}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              spouseDetails: {
                                ...prev.spouseDetails,
                                firstName: e.target.value,
                              },
                            }))
                          }
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="spouseLastName" className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter spouse's last name"
                          value={formData.spouseDetails.lastName}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              spouseDetails: {
                                ...prev.spouseDetails,
                                lastName: e.target.value,
                              },
                            }))
                          }
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="spouseDob" className="mb-3">
                        <Form.Label>Date of Birth</Form.Label>
                        <DatePicker
                          selected={formData.spouseDetails.dob}
                          onChange={(date) =>
                            setFormData((prev) => ({
                              ...prev,
                              spouseDetails: {
                                ...prev.spouseDetails,
                                dob: date,
                              },
                            }))
                          }
                          className="form-control"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <h5 className="mb-3">Spouse's Travel History</h5>
                  {/* Add Spouse Travel History */}
                  {/* ... similar to main travel/residential history */}
                </>
              )}

              {/* Submit Button */}
              <Button variant="primary" type="submit" className="w-100">
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </Layout>
  );
};

export default VisaForm;
